<template>
  <section>
    <div class="page-header">
      <h3 class="page-title">
        Banner
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
          <li class="breadcrumb-item">Reference</li>          
          <li class="breadcrumb-item active" aria-current="page">Banner</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <b-modal
        :centered="true"
        :hide-footer="true"
        :title="titleModal"
        v-model="modalForm"
      >
        <b-row>
          <b-col class="mb-3" lg="12">
            <template v-if="stateCrud != 'upload'">
              <b-row class="pt-3">
                <b-col lg="6"><label>Title</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <input type="text" v-model="form.title" class="form-control form-control-lg" placeholder="Title">

              <b-row class="pt-3">
                <b-col lg="6"><label>Headline</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <input type="text" v-model="form.headline" class="form-control form-control-lg" placeholder="Headline">              

              <b-row class="pt-3">
                <b-col lg="6"><label>Desc</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>            
              <vue-editor v-model="form.desc" :editorToolbar="customToolbar"></vue-editor>              
            </template>            
            <template v-else>
              <b-row>
                <b-col lg="6"><label>Image</label></b-col>
                <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                       
              </b-row>
              <input type="file" id="icon" ref="icon" v-on:change="handleFileUpload()"/>                  
              <!-- <input type="number" v-model="form.minimum_transaction" class="form-control form-control-lg" placeholder="Minimum Transaction"> -->
            </template>
          </b-col> 
          <b-col class="mb-3 mb-xl-0" lg="12">
            <b-input-group>
              <b-button
                variant="primary"
                href="javascript:void(0)"
                @click="sendPayload()">Save
              </b-button>
            </b-input-group>
          </b-col>                
        </b-row>
      </b-modal>
      <div class="col-lg-12 pull-right float-none float-sm-right d-block mt-3 mt-sm-1 text-right mr-1 pt-2 pl-2 pb-5">
        <button type="button"  
          class="btn btn-sm btn-primary btn-icon-text pull-right"
          @click="openAddModal()">
          Add
          <i class="mdi mdi mdi mdi-plus-circle-outline btn-icon-append"></i>
        </button>        
      </div>   
      <template v-if="data.length == 0">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-auto">
              <div class="col-lg-12 grid-margin stretch-card">
                <h2>Data Not Found</h2>
              </div>			            
            </div>
          </div>
        </div>        
      </template>
      <template>
        <div class="col-md-4" v-for="(item, index) in data" :key="index">
          <template v-if="item.image == null">
            <div class="stretch-card grid-margin" @click="openLink('detail',item)" style="cursor: pointer;">
              <div class="card bg-gradient-success card-img-holder text-white">
                <div class="card-body">
                  <!-- <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" /> -->
                  <h4 class="font-weight-normal mb-1">
                    {{ item.title }} 
                    <i class="mdi mdi-map mdi-24px float-right"></i>
                  </h4>
                  <!-- <h6 class="card-text">Route : {{ item.route_app_mobile }}</h6>             -->
                  <div class="row col-lg-12">
                    <div class="col-lg-3">
                      <button type="button"  
                        class="btn btn-sm btn-warning"
                        @click="openEditModal(item.uuid, item)">
                        <i class="text-center mdi mdi-lead-pencil"></i>
                      </button>
                    </div>

                    <div class="col-lg-3">
                      <button type="button"  
                        class="btn btn-sm btn-danger"
                        @click="openDeleteModal(item.uuid, item)">
                        <i class="text-center mdi mdi mdi-delete-variant"></i>
                      </button>              
                    </div>            
                    <div class="col-lg-3">
                      <button type="button"  
                        class="btn btn-sm btn-success"
                        @click="openUploadModal(item.uuid)">
                        <i class="text-center mdi mdi mdi-upload"></i>
                      </button>              
                    </div>
                  </div>                 
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row">
              <div class="col-lg-12 justify-content-md-center stretch-card grid-margin" @click="openLink('detail',item)" style="cursor: pointer;">
                <div class="row justify-content-md-center">
                  <div class="bg-white card-img-holder p-3">
                    <img :src="item.image" class="justify-content-md-center col-lg-12" alt="circle-image"/>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 stretch-card grid-margin text-center" @click="openLink('detail',item)" style="cursor: pointer;">
                <div class="card bg-gradient-success card-img-holder text-white">
                  <!-- <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" /> -->
                  <h4 class="font-weight-normal mb-1">
                    {{ item.title }} 
                  </h4>
                  <!-- <h6 class="card-text">Route : {{ item.route_app_mobile }}</h6>                             -->
                  <!-- <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" /> -->
                  <div class="row col-lg-12 justify-content-md-center">
                    <div class="col-lg-3">
                      <button type="button"  
                        class="btn btn-sm btn-warning"
                        @click="openEditModal(item.uuid, item)">
                        <i class="text-center mdi mdi-lead-pencil"></i>
                      </button>
                    </div>

                    <div class="col-lg-3">
                      <button type="button"  
                        class="btn btn-sm btn-danger"
                        @click="openDeleteModal(item.uuid, item)">
                        <i class="text-center mdi mdi mdi-delete-variant"></i>
                      </button>              
                    </div>            
                    <div class="col-lg-3">
                      <button type="button"  
                        class="btn btn-sm btn-success"
                        @click="openUploadModal(item.uuid)">
                        <i class="text-center mdi mdi mdi-upload"></i>
                      </button>              
                    </div>
                  </div>
                </div>
              </div>
            </div>                    
          </template>
        </div>
      </template>

      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-auto">
            <div class="col-lg-12 grid-margin stretch-card">
              <b-pagination
                v-model="page"
                :total-rows="totalRows"
                :per-page="limit"
                align="fill"
                size="md"
                class="mb-1 justify-content-md-center"
                v-on:click.native="callback"
              ></b-pagination>
            </div>			            
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'basicTables',
  data () {
    return {
      titleModal: "",
      modalForm: false,
      stateCrud: "",
      customToolbar: [
          [{'size': ['small', false, 'large']}],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link'],
          ['emoji'],
      ],      
			tableConfig : packageGlobals.table,
      data: [],
      totalRows: null,
      lastPage: null,
      page: 1,
      limit: 10,
      filter: null,
			idKey: null,
      form: {
				title: "",
        headline : "",
				desc: "",        
        // remarks: ""
      },
      formUpload: {

      },      						
      fields: [
        {key: "index", label: "No.", sortable: true, class: "text-center" },
				{key: 'name', label: 'Name', class: "text-center", sortable: true},
				// {key: 'remarks', label: 'Remarks', class: "text-center", sortable: true},        
        {key: "created_at",label: "Created At",sortable: true,class: "text-center"},
        {key: "updated_at",label: "Updated At",sortable: true,class: "text-center"},        				
				{key: 'actions',label: 'Action',class: "text-center",sortable: true}
      ],
			data404 : []
    }
  },
	methods: {
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    callback: function (page) {
      this.$router.push({ query: { page: page.target.ariaPosInSet } })
      var currenPages = page.target.ariaPosInSet == null ? 1 : page.target.ariaPosInSet
      this.getAll('-', currenPages, this.limit)
    },
    getAll: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/banner?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result.data
        for (let index = 0; index < this.data.length; index++) {
          this.data[index].image = (this.data[index].image != null) ? `${packageStore.mainApi}${this.data[index].image}` : null
        }        
        this.totalRows = response.data.result.total
        this.lastPage = response.data.result.lastPage
			})				
			
    },
    searchData: function() {
      this.getAll(this.search,this.page, this.limit)      
    },
		handleFileUpload(){			
			this.formUpload.image = this.$refs.icon.files[0];			
		},    
    openAddModal: function () {
      this.form.title = ""
      this.form.desc = ""      
      // this.form.remarks = ""      
      this.titleModal = "Add Feature"
      this.stateCrud = "add"
      this.modalForm = true
    },
    openEditModal: function (id, item) {
      this.titleModal = "Edit Feature"
      this.stateCrud = "edit"
      this.idKey = id
      this.form.title = item.title      
      this.form.desc = item.desc
      // this.form.remarks = item.remarks      
      this.modalForm = true
    },
    openUploadModal: function (id) {
      this.titleModal = "Upload Icon"
      this.stateCrud = "upload"
      this.idKey = id
      this.modalForm = true      
    },    
    openDeleteModal: function (id, item) {
      this.$swal.fire({
        title: "Are you sure you want to delete this data?",
        text: `Data : ${item.title}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      })
      .then((result) => {
				console.log(result.value);
        if (result.value) {
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "DELETE"
          axios.delete(`${packageStore.mainApi}/api/web/reference/banner/${id}/${localStorage.getItem("uuid")}`)
          .then((response) => {
            this.responseAxios(response)
          })
        }
      })
    },		
    sendPayload: function () {
      if (this.stateCrud != "upload") {
        // let checkField = packageGlobals.checkField(
        // [
        //   {value : this.form.title, message : 'field title is required', key:'title'},
        //   {value : this.form.desc, message : 'field desc is required', key:'desc'}                  
        // ]
        // )
        // console.log(checkField);
        if (this.stateCrud == "add") {
          this.created_by = localStorage.getItem("uuid")
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
          // axios.post(`${packageStore.mainApi}/api/web/reference/banner`,this.form,packageGlobals.configAxios)					
          axios.post(`${packageStore.mainApi}/api/web/reference/banner`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })
        } else if (this.stateCrud == "edit") {
          this.updated_by = localStorage.getItem("uuid")					
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          axios.put(`${packageStore.mainApi}/api/web/reference/banner/${this.idKey}`,this.form)
          .then((response) => {
            this.responseAxios(response)
          })
        }

        
      }
      else
      {
        if (this.stateCrud == "upload") {
          this.updated_by = localStorage.getItem("uuid")
          let formData = new FormData();
          formData.append('file', this.formUpload.image);          
          packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
          // this.configAxios.headers["Content-Type"] = "multipart/form-data";
          axios.put(`${packageStore.mainApi}/api/web/reference/banner/upload/${this.idKey}/${localStorage.getItem("uuid")}`,formData)
          .then((response) => {
            this.responseAxios(response)
          })
        }
      }            
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.modalForm = false
        this.$swal.fire({
          title: "Success",
          text: response.data.result.message,
          icon: "success",
          confirmButtonText: "Ok",
        })
				this.getAll('-', this.page, this.limit)	
      } else {
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, There is a mistake",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				
	},
	mounted() {
		this.getAll('-', this.page, this.limit)		
	},
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>